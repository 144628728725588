html {
	scroll-behavior: smooth;
}

option {
	color: black;
}

.loader {
	width: 120px;
	height: 120px;
	animation: pulse 1s linear infinite;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	right: 50%;
	left: 50%;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}


form {
	width: 100%;
}

.context {
	width: 100%;
	position: absolute;
	top: 50vh;

}

.context h1 {
	text-align: center;
	color: #fff;
	font-size: 50px;
}

.area {
	background: #4e54c8;
	background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
	width: 100%;
	height: 100vh;


}

.circles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(255, 255, 255, 0.2);
	animation: animate 25s linear infinite;
	bottom: -150px;

}

.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}

.circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
}

.circles li:nth-child(6) {
	left: 75%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
}

.circles li:nth-child(7) {
	left: 35%;
	width: 150px;
	height: 150px;
	animation-delay: 7s;
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 15s;
	animation-duration: 45s;
}

.circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 2s;
	animation-duration: 35s;
}

.circles li:nth-child(10) {
	left: 85%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 11s;
}

@keyframes animate {

	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}

}

/* From Uiverse.io by boryanakrasteva */
/* Customize the label (the checkbox-btn) */
.checkbox-btn {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-btn input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkbox-btn label {
	cursor: pointer;
	font-size: 14px;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	border: 2.5px solid #ffffff;
	transition: .2s linear;
}

.checkbox-btn input:checked~.checkmark {
	background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	visibility: hidden;
	opacity: 0;
	left: 50%;
	top: 40%;
	width: 10px;
	height: 14px;
	border: 2px solid #0ea021;
	filter: drop-shadow(0px 0px 10px #0ea021);
	border-width: 0 2.5px 2.5px 0;
	transition: .2s linear;
	transform: translate(-50%, -50%) rotate(-90deg) scale(0.2);
}

/* Show the checkmark when checked */
.checkbox-btn input:checked~.checkmark:after {
	visibility: visible;
	opacity: 1;
	transform: translate(-50%, -50%) rotate(0deg) scale(1);
	animation: pulse 1s ease-in;
}

.checkbox-btn input:checked~.checkmark {
	transform: rotate(45deg);
	border: none;
}

@keyframes pulse {

	0%,
	100% {
		transform: translate(-50%, -50%) rotate(0deg) scale(1);
	}

	50% {
		transform: translate(-50%, -50%) rotate(0deg) scale(1.6);
	}
}

/* From Uiverse.io by mobinkakei */
.toggler {
	width: 72px;
	margin: 0 auto;
}

.toggler input {
	display: none;
}

.toggler label {
	display: block;
	position: relative;
	width: 72px;
	height: 36px;
	border: 1px solid #d6d6d6;
	border-radius: 36px;
	background: #e4e8e8;
	cursor: pointer;
}

.toggler label::after {
	display: block;
	border-radius: 100%;
	background-color: #d7062a;
	content: '';
	animation-name: toggler-size;
	animation-duration: 0.15s;
	animation-timing-function: ease-out;
	animation-direction: forwards;
	animation-iteration-count: 1;
	animation-play-state: running;
}

.toggler label::after,
.toggler label .toggler-on,
.toggler label .toggler-off {
	position: absolute;
	top: 50%;
	left: 25%;
	width: 26px;
	height: 26px;
	transform: translateY(-50%) translateX(-50%);
	transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

.toggler input:checked+label::after,
.toggler input:checked+label .toggler-on,
.toggler input:checked+label .toggler-off {
	left: 75%;
}

.toggler input:checked+label::after {
	background-color: #50ac5d;
	animation-name: toggler-size2;
}

.toggler .toggler-on,
.toggler .toggler-off {
	opacity: 1;
	z-index: 2;
}

.toggler input:checked+label .toggler-off,
.toggler input:not(:checked)+label .toggler-on {
	width: 0;
	height: 0;
	opacity: 0;
}

.toggler .path {
	fill: none;
	stroke: #fefefe;
	stroke-width: 7px;
	stroke-linecap: round;
	stroke-miterlimit: 10;
}

@keyframes toggler-size {

	0%,
	100% {
		width: 26px;
		height: 26px;
	}

	50% {
		width: 20px;
		height: 20px;
	}
}

@keyframes toggler-size2 {

	0%,
	100% {
		width: 26px;
		height: 26px;
	}

	50% {
		width: 20px;
		height: 20px;
	}
}

.canvas001 {
	font-size: 20px;
	background: linear-gradient(30deg, #0400ff, #4ce3f7);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.canvas001:hover {
	/* background-position: right center; */
	background-size: 200% auto;
	-webkit-animation: pulse 2s infinite;
	animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
	0% {
		filter: drop-shadow(0 0 0 0 #05bada66);
	}

	70% {
		filter: drop-shadow(0 0 0 10px rgb(218 103 68 / 0%));
	}

	100% {
		filter: drop-shadow(0 0 0 0 rgb(218 103 68 / 0%));
	}
}