@tailwind base;
@tailwind components;
@tailwind utilities;


input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

.create-popup {
	@apply bg-[#0000008c] z-20 fixed top-0 h-full w-full;
}

.btn-primary {
	@apply bg-primary-500 hover:bg-primary-600 active:bg-primary-700 focus:outline-none focus:ring focus:ring-primary-300 text-center items-center transition duration-300 ease-in-out justify-center justify-items-center text-white font-bold px-8 py-4 rounded-md;
}

.btn-secondary {
	@apply bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-700 focus:outline-none focus:ring focus:ring-secondary-300 transition duration-300 ease-in-out text-center items-center justify-center justify-items-center text-white px-8 py-4 font-bold rounded-md;
}

.btn-submit {
	@apply uppercase justify-center justify-items-center text-white px-8 py-4 font-bold rounded-md bg-lime-500 hover:bg-lime-600 active:bg-lime-700 focus:outline-none focus:ring focus:ring-lime-300
}

.heading {
	@apply text-xl font-bold text-[black] dark:text-gray-900 leading-[20px]
}

.dec {
	@apply font-bold text-[#666] dark:text-[#999]
}


.addbtn {
	border: none;
	background: rgb(251, 33, 117);
	background: linear-gradient(0deg, rgba(251, 33, 117, 1) 0%, rgba(234, 76, 137, 1) 100%);
	color: #fff;
	overflow: hidden;
}

.addbtn:hover {
	text-decoration: none;
	color: #fff;
}

.addbtn:before {
	position: absolute;
	content: '';
	display: inline-block;
	top: -180px;
	left: 0;
	width: 30px;
	height: 100%;
	background-color: #fff;
	animation: shiny-btn1 3s ease-in-out infinite;
}

.addbtn:hover {
	opacity: .7;
}

.addbtn:active {
	box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .3),
		-4px -4px 6px 0 rgba(116, 125, 136, .2),
		inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
		inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}


@-webkit-keyframes shiny-btn1 {
	0% {
		-webkit-transform: scale(0) rotate(45deg);
		opacity: 0;
	}

	80% {
		-webkit-transform: scale(0) rotate(45deg);
		opacity: 0.5;
	}

	81% {
		-webkit-transform: scale(4) rotate(45deg);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(50) rotate(45deg);
		opacity: 0;
	}
}

.custom-btn {
	width: 130px;
	height: 40px;
	color: #fff;
	padding: 10px 25px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	background: transparent;
	cursor: pointer;
	transition: all 0.3s ease;
	position: relative;
	display: inline-block;
	box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
		7px 7px 20px 0px rgba(0, 0, 0, .1),
		4px 4px 5px 0px rgba(0, 0, 0, .1);
	outline: none;
}

.images {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.birthday-image {
	animation: pulse 1s linear infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	background-color: rgb(180, 118, 229);
}


.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(71, 189, 255);
	height: 5px !important;
	border-radius: 15px;

}

.card-body1 {
	border: 2px solid rgb(223, 6, 6);
	border-radius: 1.5rem;
	height: 100%;
}

.active {
	@apply bg-secondary-600 px-3 py-1 rounded-md transition delay-300 duration-300 ease-in-out shadow-sm
}

.pagination {
	@apply bg-secondary-400 px-3 py-1 rounded-md transition delay-300 duration-300 ease-in-out shadow-md
}